<template>
  <Toast />


  <!-- <DragDialog v-show="openNotepad" class="notepadDialog" :min-height="200" :min-width="200" :initial-left="100"
    :initial-top="100" :draggable="true" ref="drag-dialog">
    <template #header>
      <div style="
              height: 20px;
              padding: 24px 0px 0px 0px !important;
              width: 100%;
              display: flex;
              justify-content: end;
              align-items: center;
            ">
        <i class="pi pi-times" style="float: right; cursor: pointer" @click="this.openNotepad = false"></i>
      </div>
    </template>

    <template #body="">
      <div class="comments" id="notepad-container">
        <Graph />
      </div>
    </template>
  </DragDialog>

  <ExpandMenu v-if="userHasRoRole()" style="position: fixed; z-index: 999" ref="expand-menu">
    <template #expander="">
      <i class="pi pi-angle-double-left" style="cursor:pointer"></i>
    </template>
    <template #content="">
      <i class="pi pi-chart-bar" @click="
        this.openNotepad == true
          ? (this.openNotepad = false)
          : (this.openNotepad = true)
        " style="cursor: pointer; font-size: 1.8rem; margin-left: 5px" @contextmenu="onNotepadMenuRightClick"
        ref="notepad-button"></i>
      <div>
        <ContextMenu @mouseenter="mouseEnterContextMenu" @mouseleave="mouseLeaveContextMenu" ref="context-menu"
          id="context-menu" :model="notepadMenuItems" />
      </div>
    </template>
  </ExpandMenu> -->

  <div class="grid">
    <div class="col-12" style="padding-left: 0; padding-right: 0; padding-bottom: 0">
      <div style="padding: 20px">
        <div class="flex justify-content-center flex-wrap card-container">
          <div class="flex-column">
            <div class="flex justify-content-center">
              <img alt="Logo" :src="topbarImage()" style="width: 180px" />
            </div>
            <h6>System for Troubleshooting and Operational Risk Management</h6>
          </div>
        </div>
      </div>
    </div>

    <div v-if="userHasRoRole()" class="flex col-12 lg:col-6 xl:col-3" style="padding: 0 !important; gap: 24px">
      <div class="card">
        <div class="flex justify-content-between" style="min-width: 250px;">
          <div>
            <span class="block text-500 font-medium mb-3">Novos Incidentes (STORM)</span>
            <div class="text-900 font-medium text-xl align-items-center flex">
              {{ block1.total }}
              <Badge class="ml-3 mr-1" :value="'low' in this.block1 ? String(this.block1.low) : '0'"
                style="background-color: #307ae0" v-tooltip.top="'Baixo'"></Badge>
              <Badge class="mr-1" :value="'medium' in this.block1 ? String(this.block1.medium) : '0'
                " style="background-color: #ffca43" v-tooltip.top="'Médio'"></Badge>
              <Badge class="mr-1" :value="'high' in this.block1 ? String(this.block1.high) : '0'"
                style="background-color: #eb3e48" v-tooltip.top="'Alto'"></Badge>
              <Badge :value="'critical' in this.block1 ? String(this.block1.critical) : '0'
                " style="background-color: #754cc5" v-tooltip.top="'Crítico'"></Badge>
            </div>
          </div>

          <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
            style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-percentage text-blue-500 text-xl"></i>
          </div>
        </div>
        <div class="flex mt-4" style="white-space: nowrap">
          <span :style="{ color: block1.color }" class="font-medium">{{
            block1.metric }} </span>
          <span class="text-500 ml-1">
            que o mês passado ({{ block1.lastMonthTotal }})</span>
        </div>
      </div>

      <div class="flex flex-column" style="min-width: 200px">
        <a class="flex card align-items-center"
          href="https://app.powerbi.com/groups/me/reports/826b65ea-cffd-47c6-b239-ba334286d431/ReportSection849d099e15d3bb518875?ctid=16e7cf3f-6af4-4e76-941e-aecafb9704e9&experience=power-bi&clientSideAuth=0"
          target="_blank" style="height: 50%;">
          <div class="flex justify-content-between align-items-center" style="width: 100%">
            <div class="flex align-items-center" style="gap: 8px">
              <img src="/images/Power_BI_Logo_original.svg" style="width: 24px; height: 24px" />
              <div style="color: #05132A; font-weight: 500;">
                Incidentes
              </div>
            </div>
            <i class="pi pi-external-link" style="font-size: 1.2rem; padding: 0px 2px; color: gray"></i>
          </div>
        </a>
        <a class="flex card align-items-center"
          href="https://app.powerbi.com/groups/me/reports/c8d03f09-3423-4d6f-a64e-1aed9ed6b74a/ReportSection?ctid=16e7cf3f-6af4-4e76-941e-aecafb9704e9&experience=power-bi"
          target="_blank" style="height: 50%;">
          <div class="flex justify-content-between align-items-center" style="width: 100%">
            <div class="flex align-items-center" style="gap: 8px">
              <img src="/images/Power_BI_Logo_original.svg" style="width: 24px; height: 24px" />
              <div style="color: #05132A; font-weight: 500;">
                DRO
              </div>
            </div>
            <i class="pi pi-external-link" style="font-size: 1.2rem; padding: 0px 2px; color: gray"></i>
          </div>
        </a>
      </div>
      <div class="flex flex-column" style="min-width: 200px">
        <a class="flex card align-items-center"
          href="https://app.powerbi.com/groups/d2c459bd-02db-41d3-abac-764abb5c771a/reports/436a77d9-b5f9-4215-a498-a72d2038f8e2/ReportSection56a20bc654da561868fe?ctid=16e7cf3f-6af4-4e76-941e-aecafb9704e9&experience=power-bi"
          target="_blank" style="height: 41%;">
          <div class="flex justify-content-between align-items-center" style="width: 100%">
            <div class="flex align-items-center" style="gap: 8px">
              <img src="/images/Power_BI_Logo_original.svg" style="width: 24px; height: 24px" />
              <div style="color: #05132A; font-weight: 500;">
                Issue
              </div>
            </div>
            <i class="pi pi-external-link" style="font-size: 1.2rem; padding: 0px 2px; color: gray"></i>
          </div>
        </a>
        <a class="flex card align-items-center"
          href="https://app.powerbi.com/groups/me/reports/1bf805ad-72ad-4a3b-ba2a-3d34c435f0d4?ctid=16e7cf3f-6af4-4e76-941e-aecafb9704e9&experience=power-bi"
          target="_blank" style="height: 41%;">
          <div class="flex justify-content-between align-items-center" style="width: 100%">
            <div class="flex align-items-center" style="gap: 8px">
              <img src="/images/Power_BI_Logo_original.svg" style="width: 24px; height: 24px" />
              <div style="color: #05132A; font-weight: 500;">
                BIA
              </div>
            </div>
            <i class="pi pi-external-link" style="font-size: 1.2rem; padding: 0px 2px; color: gray"></i>
          </div>
        </a>
      </div>
    </div>


    <div class="col-12" style="padding-left: 0; padding-right: 0; padding-bottom: 0">
      <div style="padding: 20px">
        <TabView v-model:activeIndex="currentTabIndex">
          <TabPanel>
            <template #header>
              <span>{{ this.$langGetValue("incident") }}</span>
            </template>
            <TabView>
              <div v-if="userHasRoRole() == false">
                <TabPanel>
                  <template #header>
                    <span style="padding-right: 16px">{{ this.$langGetValue("myIncidents") }}</span>
                    <Badge class="badge" :value="myIncidentsSize" />
                  </template>
                  <div class="mt-2" v-if="myIncidents != null">
                    <IncidentResult :analyticsOnSearch="false" :query="myIncidents"
                      @update-length="updateLengthMyIncidents" buttonMode="visualizar">
                    </IncidentResult>
                  </div>
                  <div v-if="this.myIncidentsSize == 0 && this.incidentControl"
                    style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px;" />
                    <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
                      {{ this.$langGetValue('myIncidentsEmpty') }}
                    </div>
                  </div>
                </TabPanel>
              </div>
              <TabPanel v-if="userHasRoRole()">
                <template #header>
                  <span style="padding-right: 16px">{{ this.$langGetValue("myIncidents") }}</span>
                  <Badge class="badge" :value="incidentMenu[0].size.toString()" />
                </template>
                <div v-if="myIncidents != null">
                  <IncidentResult :analyticsOnSearch="false" :query="myIncidents" @update-length="updateLengthMyIncidents"
                    buttonMode="visualizar">
                  </IncidentResult>
                </div>
                <div v-if="this.incidentMenu[0].size == 0 && this.incidentControl"
                  style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px;" />
                  <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
                    {{ this.$langGetValue('myIncidentsEmpty') }}
                  </div>
                </div>
              </TabPanel>
              <TabPanel v-if="userHasRoRole() && incidentsToAssign != null">
                <template #header>
                  <span style="padding-right: 16px">{{ this.$langGetValue('assignIncidents') }}</span>
                  <Badge class="badge" :value="incidentMenu[1].size.toString()" />
                </template>
                <IncidentResult :analyticsOnSearch="false" :query="incidentsToAssign"
                  @update-length="updateLenghtIncidentsToAssign" buttonMode="atribuir">
                </IncidentResult>
              </TabPanel>
              <TabPanel v-if="userHasRoRole() && incidentsAssigned != null">
                <template #header>
                  <span style="padding-right: 16px">{{ this.$langGetValue('assignedIncidents') }}</span>
                  <Badge class="badge" :value="incidentMenu[2].size.toString()" />
                </template>
                <Accordion class="customAccordionTab1">
                  <AccordionTab v-for="value in incidentsAssignedArray" :key="value" style="background-color: #f9f9f9">
                    <template #header>
                      <div class="flex align-items-center justify-content-start ml-4">
                        <div class="h4" style="width: 200px;">
                          {{ emailToName(value.user) }}
                        </div>
                      </div>
                      <div class="flex justify-content-evenly align-items-center"
                        style="width:fit-content; margin-left: 16px">
                        <!-- <Badge class="mr-1" :value="roUsersRiskCount != null ? String(roUsersRiskCount[value.user].low) : '0'"
                                  style="background-color: #0066ff" v-tooltip.top="this.$langGetValue('lowMario')"></Badge> -->
                        <Badge :value="roUsersRiskCount != null
                          ? String(roUsersRiskCount[value.user].medium)
                          : '0'
                          " style="background-color: var(--color_incident_medium); margin-right: 2px"
                          v-tooltip.top="this.$langGetValue('mediumMario')"></Badge>
                        <Badge :value="roUsersRiskCount != null
                          ? String(roUsersRiskCount[value.user].high)
                          : '0'
                          " style="background-color: var(--color_incident_high); margin-right: 2px"
                          v-tooltip.top="this.$langGetValue('highMario')"></Badge>
                        <Badge :value="roUsersRiskCount != null
                          ? String(roUsersRiskCount[value.user].critical)
                          : '0'
                          " style="background-color: var(--color_incident_critical)"
                          v-tooltip.top="this.$langGetValue('criticalMario')"></Badge>
                        <div class="body3"
                          style="font-weight: 700; color: var(--color_gray_dark); margin-left: 20px; margin-right: 4px">
                          Total
                        </div>
                        <Badge :value="roUsersRiskCount != null
                          ? String(value.total)
                          : '0'
                          " style="background-color: var(--color_gray_dark);" v-tooltip.top="'Total'"></Badge>
                      </div>

                    </template>
                    <IncidentResult :analyticsOnSearch="false" :query="value.payload" buttonMode="revisar">
                    </IncidentResult>
                  </AccordionTab>
                </Accordion>
              </TabPanel>
              <TabPanel v-if="userHasRoRole() && incidentsReviewedByMe != null">
                <template #header>
                  <span style="padding-right: 16px">{{ this.$langGetValue('reviewedByMe') }}</span>
                  <Badge class="badge" :value="incidentMenu[3].size.toString()" />
                </template>
                <IncidentResult :analyticsOnSearch="false" :query="incidentsReviewedByMe"
                  @update-length="updateLenghtIncidentsReviewedByMe" buttonMode="visualizar">
                </IncidentResult>
                <div v-if="this.incidentMenu[3].size == 0"
                  style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px;" />
                  <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
                    {{ this.$langGetValue('reviewedByMeEmpty') }}
                  </div>
                </div>
              </TabPanel>
              <TabPanel v-if="userHasBDPORole() && incidentsToAssign != null">
                <template #header>
                  <span style="padding-right: 16px">BDPO</span>
                  <Badge class="badge" :value="incidentMenu[4].size.toString()" />
                </template>
                <Accordion class="customAccordionTab1">
                  <AccordionTab v-for="state in bdpoIncidentStates" :key="state">
                    <template #header>
                      <div class="flex align-items-center justify-content-start ml-4">
                        <div class="h4" style="width: 200px;">
                          {{ state.state }}
                        </div>
                      </div>
                    </template>
                    <IncidentResult :analyticsOnSearch="false" :query="incidentsToAssign" buttonMode="bdpo">
                    </IncidentResult>
                  </AccordionTab>
                </Accordion>
              </TabPanel>
            </TabView>
          </TabPanel>
          <TabPanel v-if="userHasIssueRole()">
            <template #header>
              <span>Issue</span>
            </template>
            <TabView v-if="loadIssue">
              <TabPanel>
                <template #header>
                  <span style="padding-right: 16px">{{ this.$langGetValue("myIssues") }}</span>
                  <Badge class="badge" :value="myIssuesLength.toString()" />
                </template>
                <div v-if="this.issueShow">
                  <IssueResult :analyticsOnSearch="true" :query="myIssues" buttonMode=""
                    @update-length="updateLengthMyIssues" @page-data="handlePageData">
                  </IssueResult>
                </div>
                <div v-else>
                </div>
              </TabPanel>
              <TabPanel>
                <template #header>
                  <span style="padding-right: 16px">{{ this.$langGetValue("controlAreaIssues") }}</span>
                  <Badge class="badge" :value="CAIssuesLength.toString()" />
                </template>
                <Accordion v-if="this.issueShow3" class="customAccordionTab1">
                  <AccordionTab v-for="value in CAIssuesArray" :key="value" style="background-color: #f9f9f9">
                    <template #header>
                      <div class="flex align-items-center justify-content-start ml-4">
                        <div class="h4" style="width: 200px;">
                          {{ emailToName(value.fupResponsible) }}
                        </div>
                      </div>
                      <div class="flex justify-content-evenly align-items-center"
                        style="width:fit-content; margin-left: 16px">
                        <!-- <Badge class="mr-1" :value="roUsersRiskCount != null ? String(roUsersRiskCount[value.user].low) : '0'"
                                  style="background-color: #0066ff" v-tooltip.top="this.$langGetValue('lowMario')"></Badge> -->
                        <Badge :value="this.CAIssuesCount[value.fupResponsible].countRiskIO.toString()"
                          style="background-color: var(--color_incident_improvementopportunity); margin-right: 2px"
                          v-tooltip.top="this.$langGetValue('improvement opportunityriskLevel')"></Badge>
                        <Badge :value="this.CAIssuesCount[value.fupResponsible].countRiskLow.toString()"
                          style="background-color: var(--color_incident_low); margin-right: 2px"
                          v-tooltip.top="this.$langGetValue('lowriskLevel')"></Badge>
                        <Badge :value="this.CAIssuesCount[value.fupResponsible].countRiskMedium.toString()"
                          style="background-color: var(--color_incident_medium)"
                          v-tooltip.top="this.$langGetValue('mediumriskLevel')"></Badge>
                        <Badge :value="this.CAIssuesCount[value.fupResponsible].countRiskHigh.toString()"
                          style="background-color: var(--color_incident_high)"
                          v-tooltip.top="this.$langGetValue('highriskLevel')"></Badge>
                        <div class="body3"
                          style="font-weight: 700; color: var(--color_gray_dark); margin-left: 20px; margin-right: 4px">
                          {{ this.$langGetValue('open') }}
                        </div>
                        <Badge :value="this.CAIssuesCount[value.fupResponsible].countOpen.toString()"
                          style="background-color: var(--color_gray_dark);" v-tooltip.top="this.$langGetValue('open')">
                        </Badge>
                        <div class="body3" style="font-weight: 700; color: red; margin-left: 20px; margin-right: 4px">
                          {{ this.$langGetValue('overdue') }}
                        </div>
                        <Badge :value="this.CAIssuesCount[value.fupResponsible].countOverdue.toString()"
                          style="background-color: grey;" v-tooltip.top="this.$langGetValue('overdue')"></Badge>
                      </div>
                    </template>
                    <div v-if="this.issueShow3">
                      <IssueResult :analyticsOnSearch="true" :query="value.payload" buttonMode=""
                        @update-length="updateLengthCAIssues" @update-response="updateResponseCAIssues"
                        @page-data="handlePageData">
                      </IssueResult>
                    </div>
                  </AccordionTab>
                </Accordion>
              </TabPanel>
              <TabPanel v-if="this.isApprover()">
                <template #header>
                  <span style="padding-right: 16px">{{ this.$langGetValue("issuesPendingapproval") }}</span>
                  <Badge class="badge" :value="pendingIssuesLength.toString()" />
                </template>
                <div v-if="this.issueShow2">
                  <IssueResult :analyticsOnSearch="true" :query="pendingIssues" buttonMode=""
                    @update-length="updateLengthPendingIssues" @page-data="handlePageData">
                  </IssueResult>
                </div>
              </TabPanel>
            </TabView>
          </TabPanel>
        </TabView>
      </div>
    </div>

  </div>

  <Dialog class="emailDialog" v-model:visible="sendIncidentModal" style="width: 30vw" :modal="true" :showHeader="false"
    :dismissable-mask="false">
    <Card v-if="notShowAnimation == false">
      <template #content>
        <Vue3Lottie ref="lottieContainer" :animationData="AnimationUser" :loop="1" :height="200" :width="200" />
      </template>
    </Card>
  </Dialog>
</template>
<script>

import IncidentResult from "../components/IncidentResult.vue";
import IssueResult from "../components/IssueResult.vue";
import UserService from "../service/UserService";
import FieldService from "../service/FieldService.js";
import Util from "../service/Util";
import IncidentService from "../service/IncidentService";
import EventBus from "../AppEventBus";
import AnimationUser from "../assets/animations/AnimationUser.json";
import { Vue3Lottie } from "vue3-lottie";
import IssueService from "../service/IssueService";
// import DragDialog from "../components/DragDialog.vue";
// import ExpandMenu from "../components/ExpandMenu.vue";
// import Graph from "./teste.vue";

export default {
  components: {
    IncidentResult,
    IssueResult,
    Vue3Lottie,
    // DragDialog,
    // ExpandMenu,
    // Graph
  },
  data() {
    return {
      loadIssue: false,
      currentTabIndex: 0,
      bdpoIncidentStates: [{ state: this.$langGetValue('bdpoPending'), query: "Teste" },
      { state: this.$langGetValue('bdpoFilled'), query: "Teste" }],
      // notepadInterval: null,
      openNotepad: false,
      loadingIconLocal: false,
      block1: {
        total: 0,
        metric: 0,
        color: "green",
      },
      incidentControl: false,
      myIncidentsSize: null,
      incidentMenuSelected: null,
      incidentMenu: [
        { name: this.$langGetValue("myIncidents"), id: 1, size: 0 },
        { name: this.$langGetValue("assignIncidents"), id: 2, size: 0 },
        { name: this.$langGetValue("assignedIncidents"), id: 3, size: 0 },
        { name: this.$langGetValue("reviewedByMe"), id: 4, size: 0 },
      ],
      AnimationUser,
      myIncidents: null,
      incidentsToAssign: null,
      myIssues: null,
      myIssuesPage: 0,
      myIssuesLength: 0,
      pendingIssues: null,
      pendingIssuesPage: 0,
      pendingIssuesLength: 0,
      CAIssues: null,
      CAIssuesPage: 0,
      CAIssuesLength: 0,
      issueShow: false,
      issueShow2: false,
      issueShow3: false,
      incidentsAssigned: null,
      incidentsReviewedByMe: null,
      sendIncidentModal: false,
      incidentIDLocal: null,
      selectedRoUser: null,
      notShowAnimation: true,
      roUsers: UserService.roUsers,
      roUsersRiskCount: null,
      incidentsAssignedArray: [],
      FUPResponsibleArray: [],
      CAIssuesArray: [],
      CAIssuesCount: {},
      incidentsToAssignPage: 0,
    };
  },
  watch: {
    currentTabIndex() {
      if(this.currentTabIndex == 1 && this.loadIssue == false) {
        this.loadIssue = true
      }
    },
  },
  async mounted() {
    this.mountAnalitycs();

    // this.notepadInterval = setInterval(() => this.setSize(), 100);

    this.countIncidentsAssigned();

    if (this.userHasRoRole()) {

      this.incidentMenuSelected = this.incidentMenu[0];

      if (this.userHasBDPORole()) {
        this.incidentMenu.push({ name: "BDPO", id: 5, size: 0 })
      }

      await this.verifyUserHasIncidents();

      await this.verifyIncidentsToAssign();

      await this.verifyIncidentsAssigned();

      await this.verifyIncidentsReviewedByMe();

    } else {
      await this.verifyUserHasIncidents();
    }

    if (this.userHasIssueRole()) {

      await this.verifyMyIssues();

      await this.verifyPendingIssues();

      this.mountFUPResponsibles();

    }

  },
  unmounted() {
    // clearInterval(this.notepadInterval)
  },
  created() {
    EventBus.on("force-update", this.forceUpdate);
  },
  methods: {
    incidentsAssignedSortArray() {
      if (this.incidentsAssigned) {
        for (let value of this.incidentsAssigned) {
          const total = this.roUsersRiskCount[value.user].critical + this.roUsersRiskCount[value.user].medium + this.roUsersRiskCount[value.user].high
          const newObj = {
            user: value.user,
            total: total,
            payload: value.payload
          };
          this.incidentsAssignedArray.push(newObj);
        }
        this.incidentsAssignedArray.sort((a, b) => b.total - a.total);
      }
    },
    checkRole() {
      if (
        UserService.roles.includes("inc.distribution") ||
        UserService.roles.includes("admin")
      ) {
        return true;
      }
      return false;
    },
    countIncidentsAssigned() {
      IncidentService.incidentForReviewCount().then((response) => {
        this.roUsersRiskCount = response;

        let tempTotal = 0;

        for (let user of Object.values(response)) {
          tempTotal += user.low;
          tempTotal += user.medium;
          tempTotal += user.high;
          tempTotal += user.critical;
        }

        this.incidentMenu[2].size = tempTotal;

        this.loadingIconLocal = false;

        this.incidentsAssignedSortArray();
      });
    },
    setSize() {
      var cardDiv = document.querySelector('.notepadDialog .card');

      var editorDiv = document.querySelector('.comments #chartBarIndividual');

      var heightCard = cardDiv.clientHeight;

      editorDiv.style.height = heightCard - 50 + "px";
    },
    async refresh() {
      this.loadingIconLocal = true;
      if (this.userHasRoRole()) {
        if (
          this.incidentMenuSelected != null &&
          this.incidentMenuSelected.name == this.$langGetValue("myIncidents")
        ) {
          this.myIncidents = null;

          await this.$nextTick();

          this.verifyUserHasIncidents();
        }
        if (
          this.incidentMenuSelected != null &&
          this.incidentMenuSelected.name ==
          this.$langGetValue("assignIncidents")
        ) {
          this.incidentsToAssign = null;

          await this.$nextTick();

          this.verifyIncidentsToAssign();
        }
        if (
          this.incidentMenuSelected != null &&
          this.incidentMenuSelected.name == this.$langGetValue("reviewedByMe")
        ) {
          this.incidentsReviewedByMe = null;

          await this.$nextTick();

          this.verifyIncidentsReviewedByMe();
        }
        if (
          this.incidentMenuSelected != null &&
          this.incidentMenuSelected.name ==
          this.$langGetValue("assignedIncidents")
        ) {
          this.incidentsAssignedArray = []
          this.incidentsAssigned = null;

          this.countIncidentsAssigned();

          await this.$nextTick();

          this.verifyIncidentsAssigned();
        }
      } else {
        this.myIncidents = null;

        await this.$nextTick();

        this.verifyUserHasIncidents();
      }
    },
    mountAnalitycs() {
      IncidentService.analitycs().then((response) => {
        this.mountBlockOne(response.block1);
      });
    },
    mountFUPResponsibles() {

      IssueService.getResponsibleOfControlArea(UserService.user).then((response) => {

        // Objeto para armazenar as contagens por nome
        this.CAIssuesCount = {};

        // Percorre a lista de objetos retornados pela API
        response.data?.forEach((fupR) => {
          // Verifica se o nome já está no objeto this.CAIssuesCount
          if (!(fupR.name in this.CAIssuesCount)) {
            // Se o nome não existir, inicializa as contagens com zero
            this.CAIssuesCount[fupR.name] = {
              countRiskHigh: 0,
              countRiskMedium: 0,
              countRiskLow: 0,
              countRiskIO: 0,
              countOpen: 0,
              countOverdue: 0,
              countTotal: 0
            };
            this.FUPResponsibleArray.push(fupR.name)
          }
          // Atualiza as contagens de acordo com o nível de risco
          switch (fupR.type) {
            case "critical":
              this.CAIssuesCount[fupR.name].countRiskHigh += fupR.count;
              this.CAIssuesCount[fupR.name].countTotal += fupR.count;
              break;
            case "high":
              this.CAIssuesCount[fupR.name].countRiskHigh += fupR.count;
              this.CAIssuesCount[fupR.name].countTotal += fupR.count;
              break;
            case "medium":
              this.CAIssuesCount[fupR.name].countRiskMedium += fupR.count;
              this.CAIssuesCount[fupR.name].countTotal += fupR.count;
              break;
            case "low":
              this.CAIssuesCount[fupR.name].countRiskLow += fupR.count;
              this.CAIssuesCount[fupR.name].countTotal += fupR.count;
              break;
            case "improvement opportunity":
              this.CAIssuesCount[fupR.name].countRiskIO += fupR.count;
              this.CAIssuesCount[fupR.name].countTotal += fupR.count;
              break;
            case "Atrasado":
              this.CAIssuesCount[fupR.name].countOverdue += fupR.count;
              break;
            case "open":
              this.CAIssuesCount[fupR.name].countOpen += fupR.count;
              break;
            case "pendingapproval":
              this.CAIssuesCount[fupR.name].countOpen += fupR.count;
              break;
            case "pendingrequest":
              this.CAIssuesCount[fupR.name].countOpen += fupR.count;
              break;
          }
        });

        this.reorderCAIssues();

        this.verifyCAIssues();
      })
    },
    mountBlockOne(block) {
      let tempBlock = {
        total: 0,
      };
      if (block.main) {
        block.main.forEach((data) => {
          if (data.riskLevel == "low") {
            tempBlock["low"] = data.count;
          }
          if (data.riskLevel == "medium") {
            tempBlock["medium"] = data.count;
          }
          if (data.riskLevel == "high") {
            tempBlock["high"] = data.count;
          }
          if (data.riskLevel == "critical") {
            tempBlock["critical"] = data.count;
          }
          tempBlock.total += data.count;
        });


        tempBlock.lastMonthTotal = block.sub[0].count;

        let tempValue;

        if (block.sub[0].count == 0) {
          tempValue = -100;
        } else if (tempBlock.lastMonthTotal - tempBlock.total == 0) {
          tempValue = 0;
        } else {
          tempValue = Math.round(
            ((tempBlock.lastMonthTotal - tempBlock.total) / block.sub[0].count) *
            100
          );
        }

        if (tempValue > 0) {
          tempBlock.metric = String(tempValue) + "% a menos";
          tempBlock.color = "green";
        } else {
          tempBlock.metric = String(Math.abs(tempValue)) + "% a mais";
          tempBlock.color = "red";
        }

        this.block1 = tempBlock;

      }
    },
    updateLengthMyIncidents(value) {
      this.incidentControl = true
      this.loadingIconLocal = false;
      if (this.userHasRoRole()) {
        this.incidentMenu[0].size = value;
      } else {
        this.myIncidentsSize = value;
      }
    },
    updateLengthMyIssues(value) {
      this.myIssuesLength = value;
      this.issueShow = true;
      this.loadingIconLocal = false;
    },
    updateLengthPendingIssues(value) {
      this.pendingIssuesLength = value;
      this.issueShow2 = true;
      this.loadingIconLocal = false;
    },
    updateLengthCAIssues() {
      this.CAIssuesLength = 0;
      var fupR
      for (fupR in this.CAIssuesCount) {
        this.CAIssuesLength += this.CAIssuesCount[fupR].countTotal
      }
    },
    reorderCAIssues() {
      this.FUPResponsibleArray.sort((a, b) => {
        return this.CAIssuesCount[b]?.countTotal - this.CAIssuesCount[a]?.countTotal;
      });
    },
    updateLenghtIncidentsToAssign(value) {
      this.loadingIconLocal = false;
      this.incidentMenu[1].size = value;
    },
    updateLenghtIncidentsReviewedByMe(value) {
      this.loadingIconLocal = false;
      this.incidentMenu[3].size = value;
    },
    userHasRoRole() {
      return UserService.checkUserRoleAny(["team.op-risk"])
    },
    userHasBDPORole() {
      return UserService.checkUserRoleAny(["bdpo-user"])
    },
    userHasIssueRole() {
      return UserService.checkUserRoleAny(["issue.user"])
    },
    getIDRole() {
      const rolesArray = UserService.roles;

      var objectsArray = FieldService.data.issue.issueOrigin

      // Criar um mapa para mapear roles para objetos
      const roleToIdMap = new Map();
      objectsArray.forEach(obj => {
        if (obj.data && obj.data.role) {
          roleToIdMap.set(obj.data.role, obj.id);
        }
      });

      // Array para armazenar os IDs encontrados
      const ids = [];

      // Percorrer o segundo array para encontrar os IDs
      rolesArray.forEach(role => {
        const id = roleToIdMap.get(role);
        if (id !== undefined) {
          ids.push(id);
        }
      });

      return ids
    },
    isApprover() {
      const roles = UserService.roles;
      return roles.includes("approver-issue") ? true : false
    },
    emailToName(email) {
      return Util.emailToName(email);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "images/logo_storm_white_font.png"
        : "images/logo_storm_black_font.png";
    },
    async verifyUserHasIncidents() {
      try {
        await UserService.getUser();

        const payload = {
          orderBy: [
            { field: "id", direction: "DESC" }
          ],
          sqlWhereClause: {
            condition: "OR",
            rules: [
              { field: "requestedBy", type: "string", operator: "equal", value: UserService.user },
              { field: "registeredBy", type: "string", operator: "equal", value: UserService.user }
            ]
          }
        };

        this.myIncidents = payload;
      } catch (error) {
        console.error("Error verifying if user has incidents:", error);
      }
    },
    async verifyIncidentsToAssign() {
      try {
        const payload = {
          page: this.incidentsToAssignPage,
          orderBy: [
            { field: "riskLevelValue", direction: "DESC" },
            { field: "id", direction: "ASC" }
          ],
          sqlWhereClause: {
            condition: "AND",
            rules: [
              { field: "status", type: "integer", operator: "equal", value: 2 }
            ]
          }
        };

        this.incidentsToAssign = payload;
      } catch (error) {
        console.error("Error verifying incidents to assign:", error);
      }
    },
    async verifyIncidentsAssigned() {
      try {
        this.incidentsAssigned = this.roUsers.map((roUser) => ({
          user: roUser,
          payload: {
            orderBy: [
              { field: "riskLevelValue", direction: "DESC" },
              { field: "id", direction: "ASC" }
            ],
            sqlWhereClause: {
              condition: "AND",
              rules: [
                { field: "status", type: "integer", operator: "equal", value: 3 },
                { field: "responsibleForReview", type: "string", operator: "equal", value: roUser }
              ]
            }
          }
        }));
      } catch (error) {
        console.error("Error verifying assigned incidents:", error);
      }
    },
    async verifyIncidentsReviewedByMe() {
      var payload = {
        orderBy: [
          {
            field: "riskLevelValue",
            direction: "DESC",
          },
          {
            field: "id",
            direction: "ASC",
          },
        ],
        sqlWhereClause: {
          condition: "AND",
          rules: [
            {
              field: "reviewedBy",
              type: "string",
              operator: "equal",
              value: UserService.user,
            },
            {
              field: "status",
              type: "integer",
              operator: "equal",
              value: 4,
            },
          ],
        },
      };

      this.incidentsReviewedByMe = payload;

      setTimeout(() => { }, 200);
    },
    async verifyMyIssues() {
      const payload = {
        orderBy: [
          { field: "statusOrder", direction: "ASC" },
          { field: "progress", direction: "DESC" },
          { field: "finalDate", direction: "ASC" },
        ],
        sqlWhereClause: {
          condition: "OR",
          rules: [
            {
              field: "submittedBy",
              type: "string",
              operator: "contains",
              value: UserService.user,
            },
            {
              field: "fupResponsible",
              type: "string",
              operator: "contains",
              value: UserService.user,
            },
          ],
        },
      };

      this.myIssues = payload;
      this.issueShow = true;
    },
    async verifyPendingIssues() {
      const payload = {
        orderBy: [{ field: "finalDate", direction: "ASC" }],
        sqlWhereClause: {
          condition: "AND",
          rules: [
            {
              field: "status",
              type: "integer",
              operator: "equal",
              value: 5,
            },
            {
              rules: [],
              condition: "OR",
            },
          ],
        },
      };

      const idRole = this.getIDRole();
      if (idRole?.length > 0) {
        for (const id of idRole) {
          payload.sqlWhereClause.rules[1].rules.push({
            field: "issueOrigin",
            type: "string",
            operator: "contains",
            value: id,
          });
        }
      }

      this.pendingIssues = payload;
      this.issueShow2 = true;
    },
    async verifyCAIssues() {
      this.FUPResponsibleArray.forEach((fupR) => {
        const payload = {
          orderBy: [
            { field: "statusOrder", direction: "ASC" },
            { field: "progress", direction: "DESC" },
            { field: "finalDate", direction: "ASC" },
          ],
          sqlWhereClause: {
            condition: "OR",
            rules: [
              {
                field: "fupResponsible",
                type: "string",
                operator: "contains",
                value: fupR,
              },
            ],
          },
        };

        this.CAIssuesArray.push({ fupResponsible: fupR, payload });
      });

      this.issueShow3 = true;
    },
    async forceUpdate(page) {

      if (page.tab == "toAssign") {
        this.incidentsToAssignPage = page.currentPage;

        this.incidentsToAssign = null;

        this.incidentsAssignedArray = []
        this.incidentsAssigned = null;

        this.countIncidentsAssigned();

        await this.$nextTick();

        this.verifyIncidentsToAssign();
        this.verifyIncidentsAssigned();
      } else if (page.tab == "assigned") {
        this.incidentsAssignedArray = []
        this.incidentsAssigned = null;

        this.countIncidentsAssigned();

        await this.$nextTick();

        this.verifyIncidentsAssigned();
      }
    },
  },
};
</script>

<style>
/* .customAccordionTab1 .p-accordion-tab .p-toggleable-content .p-accordion-content {
  padding: 0px;
}

.customAccordionTab1 .p-accordion-header-link {
  color: black !important;
  background: #ffffff !important;
}

.customAccordionTab1 .p-accordion-tab {
  margin-bottom: 0px;
}

.customAccordionTab1 .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border-radius: 0px !important;
} */

.p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
  border-radius: 0px;
  padding: 16px
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: var(--color_brand_xlight);
}

.p-accordion .p-accordion-tab .p-toggleable-content .p-accordion-content {
  border-radius: 0px;
  border-width: 0px 0px 1px 0px;
  padding: 0px 0px 24px 0px
}

.emailDialog .p-dialog-content {
  background-color: rgba(0, 0, 0, 0);
}

.emailDialog {
  box-shadow: none;
}

.customAutoCompleteEmail .p-autocomplete,
.customAutoCompleteEmail .p-inputtext {
  width: 100%;
}

.customAutoCompleteEmail .p-inputtext .p-autocomplete-token {
  margin-top: 3px;
  margin-left: 3px;
}

.tabBDPO .customSelectButton .p-button {
  width: 20%;
  border-radius: 0px;
  font-size: 16px;
  font-weight: regular;
  justify-content: center;
  border-width: 0px 0px 2px 0px;
  border-color: var(--color_gray_light);
  border-style: solid;
  padding: 24px 0px 24px 0px
}

.customSelectButton .p-button {
  width: 25%;
  border-radius: 0px;
  font-size: 16px;
  font-weight: regular;
  justify-content: center;
  border-width: 0px 0px 2px 0px;
  border-color: var(--color_gray_light);
  border-style: solid;
  padding: 24px 0px 24px 0px
}

.customSelectButton .p-button .p-button-label {
  color: #05132a;
}

.p-selectbutton .p-button.p-highlight {
  border-color: var(--color_brand_medium);
}

.customSelectButton {
  border-radius: 0px;
}

.customSelectButton .p-button:focus,
.buttonTab:focus {
  outline: none;
  box-shadow: none;
}

.p-overlay-badge .p-badge {
  background-color: var(--color_brand_medium)
}

.p-badge {
  padding: 0px
}
</style>
